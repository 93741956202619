<template>
  <div class="container">
    <h1>
      We lost your socks,
    </h1>
    <h1>
      very sorry about that.
    </h1>
    <p>
      Use the navigation on the left to browse our site. Maybe you can find the lost socks again.
    </p>
  </div>
</template>

<script>
export default {
  name: 'ErrorHandler'
}
</script>

<style scoped lang="scss">
.container {
  margin-top: 60px;
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-weight: normal;
  font-size: 5rem;
  text-transform: uppercase;
}
</style>